import React from "react";

// COMPONENTS
import Header from "./components/Header";
import Dashboard from "./components/Dashboard";

export default function App() {
  return (
    <>
      <Header />
      <Dashboard />
    </>
  );
}
