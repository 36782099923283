import React, {
  useReducer,
  useCallback,
  createContext,
  useEffect,
} from "react";

import { Web3Reducer } from "./reducer";

// WEB3 CONNECTION PACKAGES
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

// SET PROTOCOL
import {
  ERC20_ABI,
  VESTING_ABI,
  FACTORY_ABI,
  FACTORY_ADDRESS,
  ETHA_ADDRESS,
  ZERO_ADDRESS,
} from "./constants";

const initialState = {
  loading: true,
  account: null,
  networkId: null,
  chainId: null,
  balance: 0,
  vesting: null,
  factory: null,
  token: null,
};

export const Web3Context = createContext(initialState);

let web3, web3Modal;

export const Web3Provider = ({ children }) => {
  const [state, dispatch] = useReducer(Web3Reducer, initialState);

  const setAccount = (account) => {
    dispatch({
      type: "SET_ACCOUNT",
      payload: account,
    });
  };

  const setToken = (contract) => {
    dispatch({
      type: "SET_TOKEN",
      payload: contract,
    });
  };

  const setBalance = (balance) => {
    dispatch({
      type: "SET_BALANCE",
      payload: balance,
    });
  };

  const setLoading = (status) => {
    dispatch({
      type: "SET_LOADING",
      payload: status,
    });
  };

  const setFactory = (contract) => {
    dispatch({
      type: "SET_FACTORY",
      payload: contract,
    });
  };

  const setVesting = (contract) => {
    dispatch({
      type: "SET_VESTING",
      payload: contract,
    });
  };

  const setNetworkId = (networkId) => {
    dispatch({
      type: "SET_NETWORK_ID",
      payload: networkId,
    });
  };

  const logout = async () => {
    setAccount(null);
    setLoading(true);
    await web3Modal.clearCachedProvider();
    localStorage.setItem("defaultWallet", null);
  };

  const setData = async (address) => {
    let networkId;
    if (web3.givenProvider) networkId = await web3.givenProvider.networkVersion;
    else networkId = await web3.eth.net.getId();
    setNetworkId(Number(networkId));

    setAccount(address);

    const balance = await web3.eth.getBalance(address);
    setBalance(String(web3.utils.fromWei(balance)));

    window.factory = new web3.eth.Contract(FACTORY_ABI, FACTORY_ADDRESS);
    setFactory(window.factory);

    window.token = new web3.eth.Contract(ERC20_ABI, ETHA_ADDRESS);
    setToken(window.token);

    const vestingAddress = await window.factory.methods
      .getVestingContract(address)
      .call();

    if (vestingAddress !== ZERO_ADDRESS) {
      console.log("vesting address", vestingAddress);
      window.vesting = new web3.eth.Contract(VESTING_ABI, vestingAddress, {
        from: address,
      });
      setVesting(window.vesting);
    } else console.log("No vesting address found!");
  };

  // Connect to Web3 Wallet, create contract instances
  const connectWeb3 = useCallback(async () => {
    // Web3 Modal
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: "36bbdc3ed5bd449fad0374a2e07b850a", // required
        },
      },
    };

    try {
      web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true, // optional
        providerOptions, // required
        theme: "light",
      });

      const provider = await web3Modal.connect();

      web3 = new Web3(provider);
      window.web3 = web3;

      const [user] = await web3.eth.getAccounts();
      await setData(user);
      console.log("Connected Account: ", user);

      // Subscribe to accounts change
      provider.on("accountsChanged", (accounts) => {
        console.log("accountsChanged", accounts[0]);
        setData(accounts[0]);
      });

      // Subscribe to chainId change
      provider.on("chainChanged", (chainId) => {
        console.log("chainChanged", chainId);
        window.location.reload();
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toWei = (value) => web3.utils.toWei(String(value));
  const fromWei = (value) => Number(web3.utils.fromWei(String(value)));

  useEffect(() => {
    connectWeb3();
  }, [connectWeb3]);

  return (
    <Web3Context.Provider
      value={{
        ...state,
        connectWeb3,
        logout,
        toWei,
        fromWei,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};
