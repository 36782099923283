import React, { useState, useContext, useEffect, useCallback } from "react";
import { Table, Button, Container } from "react-bootstrap";
import { Web3Context } from "../web3";
import Notification from "./Notification";

export default function Dashboard() {
  const { account, vesting, token, loading, fromWei, networkId } = useContext(
    Web3Context
  );

  const [vestings, setVestings] = useState([]);
  const [released, setReleased] = useState(0);
  const [balance, setBalance] = useState(0);
  const [vestedBalance, setVestedBalance] = useState(0);
  const [dataFetched, setDataFetched] = useState(false);
  const [notify, setNotify] = useState({
    open: false,
  });

  const format = (value) => Number(value).toLocaleString();

  const fetchUserData = useCallback(async () => {
    try {
      if (!vesting) return setDataFetched(true);

      console.log("fetching data...");

      const {
        totalPeriods,
        totalReleased,
        releasedPeriods,
      } = await vesting.methods.getGlobalData().call();

      const _balance = await token.methods.balanceOf(account).call();
      const _vestedBalance = await token.methods
        .balanceOf(vesting._address)
        .call();
      const { timestamp: currentTime } = await window.web3.eth.getBlock(
        "latest"
      );

      setBalance(fromWei(_balance));
      setVestedBalance(fromWei(_vestedBalance));
      setReleased(fromWei(totalReleased));

      let _vestings = [];

      for (let i = Number(releasedPeriods); i < Number(totalPeriods); i++) {
        const { amount, timestamp } = await vesting.methods
          .getPeriodData(i)
          .call();
        _vestings.push({
          amount: format(fromWei(amount)),
          date: new Date(timestamp * 1000).toUTCString(),
          claimable: Number(currentTime) > Number(timestamp),
        });
      }

      setVestings(_vestings);
      setDataFetched(true);
    } catch (error) {
      console.error(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vesting, token]);

  const release = useCallback(async () => {
    try {
      if (!vesting) return;

      vesting.methods
        .release()
        .send()
        .on("transactionHash", function (hash) {
          setNotify({
            open: true,
            title: "Pending",
            content: `Claim transaction pending. Hash:${hash}`,
          });
          console.log(hash);
        })
        .on("receipt", function (receipt) {
          setNotify({
            open: true,
            title: "Confirmed",
            content: "Successfully claimed vested tokens!",
          });
          setTimeout(() => {
            setDataFetched(false);
            fetchUserData();
          }, 3000);
        })
        .on("error", function (error) {
          setNotify({
            open: true,
            title: "Error",
            content: error.message.slice(0, 30),
          });
          console.log(error.message);
        });
    } catch (error) {
      console.error(error.message);
    }
  }, [vesting, fetchUserData]);

  useEffect(() => {
    if (!loading) {
      fetchUserData();
    }

    return () => {
      setDataFetched(false);
      setVestings([]);
    };
  }, [loading, fetchUserData]);

  if (account && networkId !== 1)
    return (
      <div className="app-container mt-5 text-center">
        <h4>Please switch to Ethereum Mainnet!</h4>
      </div>
    );

  return (
    <div className="app-container">
      {notify.open && (
        <Notification
          title={notify.title}
          content={notify.content}
          type={notify.type}
          close={() => setNotify({ open: false })}
        />
      )}
      <h1 className="mt-5">Vesting Schedule</h1>
      {dataFetched && vesting && (
        <h6 className="mt-4 mb-3">
          Vesting Contract:{" "}
          <a
            href={`https://rinkeby.etherscan.io/address/${vesting._address}`}
            target="_blank"
            rel="noreferrer"
            className="account-link"
          >
            {vesting._address}
          </a>
        </h6>
      )}
      {dataFetched && (
        <h6 className="mt-3">Your Balance: {format(balance)} ETHA</h6>
      )}
      {dataFetched && (
        <h6 className="mb-4">
          Released/Vested: {format(released)} / {format(vestedBalance)} ETHA
        </h6>
      )}

      <Container className="w-75">
        {account && !dataFetched && <h4>Loading Data...</h4>}
        {!account && <h4>Connect web3 wallet first!</h4>}
        {dataFetched && vestings.length === 0 && <h4>No Vesting Found</h4>}
        {!loading && vestings.length > 0 && (
          <Table responsive striped bordered className="text-center text-white">
            <thead>
              <tr>
                <th>#</th>
                <th>Release Amount</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {vestings.map((s, key) => (
                <tr
                  key={key}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <td>{key + 1}</td>
                  <td>{s.amount}</td>
                  <td>{s.date}</td>
                  <td>
                    <Button
                      size="sm"
                      className="claim-button"
                      onClick={release}
                      disabled={!s.claimable}
                    >
                      {s.claimable ? "CLAIM" : "PENDING"}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Container>
    </div>
  );
}
