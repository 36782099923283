import React from "react";
import { Modal } from "react-bootstrap";

export default function Notification({ title, content, close }) {
  return (
    <Modal
      show
      onHide={close}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="notify-modal">
        {title && <Modal.Title>{title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body className="text-dark text-wrap text-break">
        {content}
      </Modal.Body>
    </Modal>
  );
}
